import React from "react";
import { useTranslation } from "react-i18next";
import { useLocale } from "../../i18n/i18n";
import Heading from "../Heading";
import LinkList from "../LinkList";
import { hotels } from "./config";

const PopularHotels: React.FC = () => {
  const locale = useLocale();
  const { t } = useTranslation("travelpage");

  return (
    <>
      <Heading>{t("popularHotels.heading")}</Heading>
      <LinkList links={hotels[locale]} />
    </>
  );
};

export default PopularHotels;
