import Locale from "../../Locale.enum";

enum Links {
  FLIGHT = "flight",
  HOTEL = "hotel",
  FEWO = "fewo",
  CAR = "car",
  TRAVEL = "travel",
  SHOPPING = "shopping",
}

export type LinkConfig = {
  url: string;
  target?: "_blank";
  rel?: string;
  selected?: Boolean;
};
export type LinksConfig = {
  [link: string]: LinkConfig;
};

export type LocaleMenuConfig = { [locale: string]: LinksConfig };
// international hotel links are temporary disabled until partner migration is over. (RFF-951)
export const menuConfig: LocaleMenuConfig = {
  [Locale.DE_DE]: {
    [Links.FLIGHT]: { url: "//flug.idealo.de" },
    [Links.HOTEL]: { url: "//hotel.idealo.de", target: "_blank" },
    [Links.FEWO]: {
      url: "//hotel.idealo.de/ferienwohnungen/",
      target: "_blank",
    },
    [Links.CAR]: { url: "//mietwagen.idealo.de", target: "_blank" },
    [Links.TRAVEL]: { url: "//reise.idealo.de", selected: true },
    [Links.SHOPPING]: { url: "//www.idealo.de", target: "_blank" },
  },
  [Locale.EN_GB]: {
    [Links.FLIGHT]: { url: "//www.flights-idealo.co.uk" },
    // [Links.HOTEL]: {
    //   url: "https://www.hotel-idealo.com/?CurrencyCode=GBP",
    //   target: "_blank",
    // },
    [Links.TRAVEL]: { url: "//travel.idealo.co.uk", selected: true },
    [Links.SHOPPING]: { url: "//www.idealo.co.uk", target: "_blank" },
  },
  [Locale.EN_US]: {
    [Links.FLIGHT]: { url: "//flights.idealo.com" },
    // [Links.HOTEL]: { url: "https://www.hotel-idealo.com", target: "_blank" },
    [Links.TRAVEL]: { url: "//travel.idealo.com", selected: true },
  },
  [Locale.ES_ES]: {
    [Links.FLIGHT]: { url: "//vuelos.idealo.es" },
    // [Links.HOTEL]: {
    //   url: "https://www.hotel-idealo.com/?LanguageCode=ES&CurrencyCode=EUR",
    //   target: "_blank",
    // },
    [Links.TRAVEL]: { url: "//viajes.idealo.es", selected: true },
    [Links.SHOPPING]: { url: "//www.idealo.es", target: "_blank" },
  },
  [Locale.FR_FR]: {
    [Links.FLIGHT]: { url: "//vols.idealo.fr" },
    // [Links.HOTEL]: {
    //   url: "https://www.hotel-idealo.com/?LanguageCode=FR&CurrencyCode=EUR",
    //   target: "_blank",
    // },
    [Links.TRAVEL]: { url: "//voyage.idealo.fr", selected: true },
    [Links.SHOPPING]: { url: "//www.idealo.fr", target: "_blank" },
  },
  [Locale.IT_IT]: {
    [Links.FLIGHT]: { url: "//voli.idealo.it" },
    // [Links.HOTEL]: {
    //   url: "https://www.hotel-idealo.com/?LanguageCode=IT&CurrencyCode=EUR",
    //   target: "_blank",
    // },
    [Links.TRAVEL]: { url: "//viaggi.idealo.it", selected: true },
    [Links.SHOPPING]: { url: "//www.idealo.it", target: "_blank" },
  },
  [Locale.NL_NL]: {
    [Links.FLIGHT]: { url: "//vliegen.idealo.nl" },
    // [Links.HOTEL]: {
    //   url: "https://www.hotel-idealo.com/?LanguageCode=NL&CurrencyCode=EUR",
    //   target: "_blank",
    // },
    [Links.TRAVEL]: { url: "//reizen.idealo.nl", selected: true },
  },
  [Locale.PL_PL]: {
    [Links.FLIGHT]: { url: "//loty.idealo.pl" },
    // [Links.HOTEL]: {
    //   url: "https://www.hotel-idealo.com/?LanguageCode=PL&CurrencyCode=PLN",
    //   target: "_blank",
    // },
    [Links.TRAVEL]: { url: "//podroz.idealo.pl", selected: true },
  },
  [Locale.PT_PT]: {
    [Links.FLIGHT]: { url: "//voos.idealo.pt" },
    // [Links.HOTEL]: {
    //   url: "https://www.hotel-idealo.com/?LanguageCode=PT&CurrencyCode=EUR",
    //   target: "_blank",
    // },
    [Links.TRAVEL]: { url: "//viagens.idealo.pt", selected: true },
  },
};
