import Locale from "../../Locale.enum";
import de_DE from "./de_DE.json";

export interface Link {
  url: string;
  title: string;
  text: string;
}

export const hotels: { [locale: string]: Link[] } = {
  [Locale.DE_DE]: de_DE,
};
